import { useEffect, useState } from "react";
import {
  getAppNotifications,
  deleteAppNotification,
  getDeviceDetails,
} from "../utilities/helper";
import { useNavigate } from "react-router-dom";

const TestBridgePage = () => {
  const [deviceData, setDeviceData] = useState({});
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  const receiveNotifications = (data) => {
    console.log("Received notifications", data);
    setNotifications(JSON.parse(data));
  };

  const receiveDeviceDetails = (data) => {
    console.log("Received device details", data);
    setDeviceData(JSON.parse(data));
  };

  useEffect(() => {
    window.receiveAppInboxNotifications = receiveNotifications;
    console.log("Setting receiveAddInboxNotifications");

    window.receiveDeviceDetails = receiveDeviceDetails;
    console.log("Setting receiveDeviceDetails");
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <h1>Test Bridge</h1>
      <button
        onClick={() => {
          getAppNotifications();
          console.log("Getting notifications");

          getDeviceDetails();
          console.log("Getting device details");
        }}
      >
        Reload
      </button>
      <h2>Device Details</h2>
      <p className="max-w-screen">{JSON.stringify(deviceData, null, 2)}</p>
      <h2>Notifications</h2>
      <div className="flex flex-col gap-4">
        {notifications.map((notification) => (
          <div
            className="flex flex-col items-center justify-center"
            key={notification.actionUrl}
          >
            <p
              onClick={() => {
                if (notification.actionUrl) {
                  navigate(notification.actionUrl);
                }
              }}
            >
              {JSON.stringify(notification, null, 2)}
            </p>
            <button
              onClick={() => deleteAppNotification(notification.actionUrl)}
            >
              Delete
            </button>
          </div>
        ))}
      </div>
      <button
        className="cursor-pointer"
        onClick={() => deleteAppNotification()}
      >
        Delete All
      </button>
    </div>
  );
};

export default TestBridgePage;

import {
  SmartHealthDataTypes,
  authorizeHealthData,
  disconnectHealthData,
  openHKLogs,
  sendHealthData,
} from "../../utilities/smartHealthHelper";
import React, { useState } from "react";
import { message } from "antd";

const HKTestPage = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const [selectedDataTypes, setSelectedDataTypes] = useState([]);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());

  const handleCheckboxChange = (dataType) => {
    setSelectedDataTypes((prev) =>
      prev.includes(dataType)
        ? prev.filter((type) => type !== dataType)
        : [...prev, dataType]
    );
  };

  return (
    <div className="flex flex-col justify-center items-center">
      {contextHolder}
      <div className="flex justify-center items-center flex-col">
        <h3>Select Data Types:</h3>
        {Object.entries(SmartHealthDataTypes).map(([name, dataType]) => (
          <div key={dataType}>
            <label>
              <input
                type="checkbox"
                value={dataType}
                checked={selectedDataTypes.includes(dataType)}
                onChange={() => handleCheckboxChange(dataType)}
              />
              {name}
            </label>
          </div>
        ))}
        <div className="flex flex-col justify-center items-center">
          <h3>Select Time Range:</h3>
          <label>
            Start Time:
            <input
              type="datetime-local"
              onChange={(e) => setStartTime(new Date(e.target.value))}
            />
          </label>
          <label>
            End Time:
            <input
              type="datetime-local"
              onChange={(e) => setEndTime(new Date(e.target.value))}
            />
          </label>
        </div>
      </div>
      <button
        className="bg-blue-500 text-white p-2 rounded mt-4"
        onClick={() => {
          authorizeHealthData(selectedDataTypes);
          messageApi.success("Authorization request sent");
        }}
      >
        Authorize Selected Data
      </button>
      <button
        className="bg-blue-500 text-white p-2 rounded mt-4"
        onClick={() => {
          disconnectHealthData(selectedDataTypes);
          messageApi.success("Disconnection request sent");
        }}
      >
        Disconnect Selected Data
      </button>
      <button
        className="bg-blue-500 text-white p-2 rounded mt-4"
        onClick={() => {
          sendHealthData(
            startTime.toISOString(),
            endTime.toISOString(),
            selectedDataTypes
          );
          messageApi.success("Data sent successfully");
        }}
      >
        Send Selected Data
      </button>
      <button
        className="bg-blue-500 text-white p-2 rounded mt-4"
        onClick={() => {
          openHKLogs();
          messageApi.success("Health Connect logs opened");
        }}
      >
        Open Health Connect Logs
      </button>
    </div>
  );
};

export default HKTestPage;

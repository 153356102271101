import BaseLayout from "../layout/BaseLayout";
import { useTranslation } from "react-i18next";
import mailIcon from "../assets/images/account/contact/mail.svg";
import RequireAuth from "../hocs/RequireAuth";
import { openExternalLink } from "../utilities/helper";
import RatingsModule from "../components/RatingsModule";

const contactList = [
  {
    title: "Email Us",
    subtitle: "Email Us",
    icon: mailIcon,
    link: "mailto:hello@jivi.ai",
  },
];

const Support = () => {
  const { t } = useTranslation();

  return (
    <BaseLayout title={t("support")}>
      <div className="flex flex-col h-full">
        <p className="text-title-medium pl-4 my-4">
          {t("fee_free_to_contact_us")}
        </p>
        <div className="flex flex-col gap-2">
          {contactList.map((contact, index) => {
            return (
              <div
                key={index}
                className="flex flex-row w-full justify-between items-center gap-4 p-4 cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  openExternalLink(contact.link, contact.type);
                }}
              >
                <div className="flex items-center gap-4">
                  <div className="min-w-8 min-h-8 p-2 border-2 rounded-xl">
                    <img className="w-6 h-6" src={contact.icon} />
                  </div>
                  <p className="text-title-medium">{contact.title}</p>
                </div>
                <p className="text-body-small opacity-40">{contact.subtitle}</p>
              </div>
            );
          })}
        </div>
        <RatingsModule />
      </div>
    </BaseLayout>
  );
};
export default RequireAuth(Support);

import * as SERVICEs from "../services/Nutrition";

export const invokeWorkflow = (workflowId, payload = {}, headers = {}) => {
  return SERVICEs.invokeWorkflow(workflowId, payload, headers)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const logMeal = (payload) => {
  return SERVICEs.logMeal(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const createMeal = (payload) => {
  return SERVICEs.createMeal(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const addMeal = (payload) => {
  return SERVICEs.addMeal(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPlan = (payload) => {
  return SERVICEs.getPlan(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPlanDetails = (payload) => {
  return SERVICEs.getPlanDetails(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getLoggedMeals = (payload) => {
  return SERVICEs.getLoggedMeals(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPlanAnalysis = (payload) => {
  return SERVICEs.getPlanAnalysis(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const putPlanStatus = (payload) => {
  return SERVICEs.updatePlanStatus(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAllPlans = (payload) => {
  return SERVICEs.getAllPlans(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const customisePlan = (payload) => {
  return SERVICEs.customisePlan(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const createAsyncPlan = (payload) => {
  return SERVICEs.createAsyncPlan(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
}
import * as services from "../services/Rating";

export const createRating = (rating) => {
  return services
    .createRating(rating)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getRatings = () => {
  return services
    .getRatings()
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateRating = (id, rating) => {
  return services
    .updateRating(id, rating)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

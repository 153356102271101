import successIcon from "../assets/images/payment/success.svg";
import failedIcon from "../assets/images/payment/failed.svg";
import pendingIcon from "../assets/images/payment/pending.svg";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import PrimaryButton from "../subComponents/PrimaryButton";
import { Spin } from "antd";
import { getPaymentDetails } from "../dataManager/Payments";
import { useEffect } from "react";
import { putPlanStatus } from "../dataManager/Nutrition";
import { logSentryError } from "../utilities/helper";
import { useTranslation } from "react-i18next";

const PaymentStatusPage = () => {
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const redirect = searchParams.get("redirect")
    ? decodeURIComponent(searchParams.get("redirect"))
    : "/";
  const orderId = searchParams.get("orderId");
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const getPaymentStatusFromApi = () => {
    getPaymentDetails(orderId)
      .then((res) => {
        const response = res.data.data;
        if (
          ["success", "error", "failed", "invalid", "cancelled"].includes(
            response.status.toLowerCase()
          )
        ) {
          switch (response.productId) {
            case "jivi_membership_gold_3_months":
              if (response.status.toLowerCase() == "success") {
                navigate(
                  `/payment-status?status=success&redirect=${encodeURIComponent(`/membership`)}`,
                  { replace: true }
                );
              } else if (
                response.status.toLowerCase() == "error" ||
                response.status.toLowerCase() == "failed"
              ) {
                navigate(
                  `/payment-status?status=error&redirect=${encodeURIComponent(`/membership`)}`,
                  {
                    replace: true,
                  }
                );
              } else if (response.status.toLowerCase() == "invalid") {
                navigate(
                  `/payment-status?status=invalid&redirect=${encodeURIComponent(`/membership`)}`,
                  { replace: true }
                );
              } else if (response.status.toLowerCase() == "cancelled") {
                navigate(
                  `/payment-status?status=cancelled&redirect=${encodeURIComponent(`/membership`)}`,
                  { replace: true }
                );
              }
              break;
            case "nutritionist":
              const redirectUrlParams = `sessionId=${response.metadata.sessionId}&planId=${response.metadata.planId}`;
              if (response.status.toLowerCase() == "success") {
                putPlanStatus({
                  planId: response.metadata.planId,
                  status: "FOLLOWED",
                })
                  .then(() => {
                    navigate(
                      `/payment-status?status=success&redirect=${encodeURIComponent(`/diet-plan?${redirectUrlParams}`)}`,
                      { replace: true }
                    );
                  })
                  .catch((err) => {
                    logSentryError(err);
                    messageApi.error(t("something_went_wrong"));
                  });
              } else if (
                response.status.toLowerCase() == "error" ||
                response.status.toLowerCase() == "failed"
              ) {
                navigate(
                  `/payment-status?status=error&redirect=${encodeURIComponent(`/diet-plan-details?${redirectUrlParams}`)}`,
                  { replace: true }
                );
              } else if (response.status.toLowerCase() == "invalid") {
                navigate(
                  `/payment-status?status=invalid&redirect=${encodeURIComponent(`/diet-plan-details?${redirectUrlParams}`)}`,
                  { replace: true }
                );
              } else if (response.status.toLowerCase() == "cancelled") {
                navigate(
                  `/payment-status?status=cancelled&redirect=${encodeURIComponent(`/diet-plan-details?${redirectUrlParams}`)}`,
                  { replace: true }
                );
              }
              break;
          }
        } else {
          setTimeout(() => {
            getPaymentStatusFromApi();
          }, 5000);
        }
      })
      .catch((err) => {
        logSentryError(err);
      });
  };

  useEffect(() => {
    if (status == "payment-pending" && orderId) {
      getPaymentStatusFromApi();
    }
  }, [orderId, status]);

  const paymentStatuses = {
    success: {
      icon: successIcon,
      status: ["success"],
      title: t("congratulations"),
      subtitle: t("your_purchase_was_successful"),
      ctaText: "Continue",
      ctaClickHandler: () => {
        navigate(redirect, { replace: true });
      },
    },
    pending: {
      icon: pendingIcon,
      status: ["pending"],
      title: t("please_wait"),
      subtitle: t("please_wait_while_we_retrieve_your_payment_status"),
      ctaText: "Back",
      ctaClickHandler: () => {
        navigate(-1, { replace: true });
      },
    },
    cancelled: {
      icon: pendingIcon,
      status: ["cancelled"],
      title: t("payment_cancelled"),
      subtitle: t("your_payment_has_been_cancelled_please_try_again"),
      ctaText: "Retry Payment",
      ctaClickHandler: () => {
        navigate(-1, { replace: true });
      },
    },
    failed: {
      icon: failedIcon,
      status: ["failed", "error"],
      title: t("payment_failed"),
      subtitle: t("your_payment_has_failed_please_try_again"),
      ctaText: "Retry Payment",
      ctaClickHandler: () => {
        navigate(-1, { replace: true });
      },
    },
    invalid: {
      icon: failedIcon,
      status: ["invalid"],
      title: t("invalid_data_received"),
      subtitle: t("your_payment_has_failed_please_try_again"),
      ctaText: "Retry Payment",
      ctaClickHandler: () => {
        navigate(-1, { replace: true });
      },
    },
    "payment-pending": {
      icon: pendingIcon,
      status: ["payment-pending"],
      title: t("please_wait"),
      subtitle: t("please_wait_while_we_retrieve_your_payment_status"),
      loading: true,
    },
  };

  return (
    <div className="min-h-full flex flex-col items-center justify-center">
      {status && (
        <PaymentStatusComponent
          {...Object.values(paymentStatuses).find((value) =>
            value.status.includes(status)
          )}
        />
      )}
    </div>
  );
};

const PaymentStatusComponent = ({
  icon,
  title,
  subtitle,
  ctaText,
  ctaClickHandler,
  loading,
}) => {
  return (
    <div className="flex flex-col items-center gap-4">
      <img height={70} width={70} src={icon} alt="payment status" />
      <p className="text-body-large">{title}</p>
      <p className="text-body-medium opacity-80 break-all">{subtitle}</p>
      {loading && <Spin />}
      {ctaText && (
        <PrimaryButton
          text={ctaText}
          className="bg-primary text-white py-2 px-4 rounded mt-4"
          onClick={() => ctaClickHandler()}
        />
      )}
    </div>
  );
};

export default PaymentStatusPage;

import { logSentryError } from "./helper";

export const SmartHealthDataTypes = {
  HEART_RATE: "HeartRate",
  OXYGEN_SATURATION: "OxygenSaturation",
  VO2_MAX: "Vo2Max",
  BLOOD_GLUCOSE: "BloodGlucose",
  BLOOD_PRESSURE_SYSTOLIC: "BloodPressureSystolic",
  BLOOD_PRESSURE_DIASTOLIC: "BloodPressureDiastolic",
  STEP_COUNT: "StepCount",
  WORKOUT: "Workout",
  ECG: "ECG",
};

export const authorizeHealthData = (dataTypes) => {
  console.log("authorizing health data", dataTypes);

  try {
    if (typeof AndroidInterface !== typeof undefined) {
      AndroidInterface.connectHealthConnect();
    } else if (window.webkit?.messageHandlers?.iosInterface) {
      window.webkit.messageHandlers.iosInterface.postMessage({
        action: "authorizeHealthData",
        data: {
          dataTypes: dataTypes,
        },
      });
    }
  } catch (e) {
    logSentryError(e);
  }
};

export const disconnectHealthData = (dataTypes) => {
  console.log("disconnecting health data", dataTypes);
  try {
    if (typeof AndroidInterface !== typeof undefined) {
      AndroidInterface.disconnectHealthConnect();
    } else if (window.webkit?.messageHandlers?.iosInterface) {
      window.webkit.messageHandlers.iosInterface.postMessage({
        action: "disconnectHealthData",
        data: {
          dataTypes: dataTypes,
        },
      });
    }
  } catch (e) {
    logSentryError(e);
  }
};

export const sendHealthData = (startTime, endTime, dataTypes) => {
  const startTimeUTC = new Date(startTime)
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");
  const endTimeUTC = new Date(endTime)
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");
  console.log("sending health data", startTimeUTC, endTimeUTC, dataTypes);
  try {
    const data = {
      startTime: startTime,
      endTime: endTime,
      dataTypes: dataTypes,
    };

    if (typeof AndroidInterface !== typeof undefined) {
      AndroidInterface.syncHealthConnectData();
    } else if (window.webkit?.messageHandlers?.iosInterface) {
      window.webkit.messageHandlers.iosInterface.postMessage({
        action: "sendHealthData",
        data: data,
      });
    }
  } catch (e) {
    logSentryError(e);
  }
};

export const openHKLogs = () => {
  try {
    if (typeof AndroidInterface !== typeof undefined) {
      AndroidInterface.openHKLogs();
    } else if (window.webkit?.messageHandlers?.iosInterface) {
      window.webkit.messageHandlers.iosInterface.postMessage({
        action: "openHKLogs",
      });
    }
  } catch (e) {
    logSentryError(e);
  }
};

import back from "../assets/images/navigation/back.svg";
import nutritionist from "../assets/images/navigation/nutritionist.png";
import mealPlan from "../assets/images/navigation/mealPlan.svg";
import dashboard from "../assets/images/navigation/dashboard.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { analyticsAppTrack, isKiosk } from "../utilities/helper";

const BottomNavigationNutritionist = ({ urlParams }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigationItems = [
    {
      name: t("back"),
      icon: back,
      url: "/nutrition",
      mixpanelKey: "Back",
      baseUrl: "/nutrition",
      isVisible: true,
    },
    {
      name: t("meal_plan"),
      icon: mealPlan,
      url: urlParams?.planId
        ? `/diet-plan?planId=${urlParams?.planId}`
        : "/diet-plan",
      mixpanelKey: "Meal Plan",
      baseUrl: "/diet-plan",
      isVisible: true,
    },
    {
      name: t("dashboard"),
      icon: dashboard,
      url: urlParams?.planId
        ? `/nutritionist-dashboard?planId=${urlParams?.planId}`
        : "/nutritionist-dashboard",
      baseUrl: "/nutritionist-dashboard",
      mixpanelKey: "Dashboard",
      isVisible: true,
    },
  ];

  const getIsActive = (item) => {
    return (
      import.meta.env.VITE_API_URL_PREFIX + item.baseUrl.replace("/", "") ===
      window.location.pathname
    );
  };
  return (
    <>
      <div
        className={`${isKiosk() ? "w-full" : "md:max-w-[430px]"} bottom-0 left-auto right-auto flex justify-evenly bg-white rounded-t-3xl w-full shadow-2xl border-t border-x p-2 py-3 z-50`}
      >
        {navigationItems.map(
          (item, index) =>
            item.isVisible && (
              <div
                key={index}
                onClick={() => {
                  analyticsAppTrack("Bottom nav bar", {
                    key: item.mixpanelKey,
                  });
                  navigate(item.url);
                }}
                className={`flex flex-col justify-start gap-2 items-center basis-1/5 cursor-pointer ${
                  !getIsActive(item) && "disabled-icon"
                }`}
              >
                <img
                  src={item.icon}
                  alt={item.name}
                  className={`h-5`}
                  draggable={false}
                />
                <p
                  className={`text-center text-body-small w-[80%] truncate text-ellipsis overflow-hidden h-5 ${
                    getIsActive(item) && "text-primary"
                  }`}
                >
                  {item.name}
                </p>
              </div>
            )
        )}
      </div>
    </>
  );
};

export default BottomNavigationNutritionist;

import * as APIs from "../constants/BackendConstant";
import { axiosInstance } from "../utilities/configureAxios";
import * as ApiResponse from "../constants/ApiResponse";
import { getMockValue } from "../utilities/helper";

export const invokeWorkflow = (workflowId, payload = {}, headers = {}) => {
  let url = APIs.INVOKE_WORKFLOW;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.send_nutrition_chat_message);
  }
  return axiosInstance.post(
    url,
    {
      workflow_id: workflowId,
      input_args: {
        inputs: {
          ...payload,
        },
      },
    },
    {
      headers: headers,
    }
  );
};

export const logMeal = (payload) => {
  let url = APIs.LOG_MEAL;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.log_meal);
  }
  return axiosInstance.post(url, payload);
};

export const createMeal = (payload) => {
  let url = APIs.CREATE_PLAN;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.create_meal);
  }
  return axiosInstance.post(url, payload);
};

export const addMeal = (payload) => {
  let url = APIs.ADD_MEAL;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.add_meal);
  }
  return axiosInstance.post(url, payload);
};

export const getPlan = (payload) => {
  let params = [];
  if (payload.sessionId) {
    params.push(`sessionId=${payload.sessionId}`);
  }
  if (payload.planId) {
    params.push(`planId=${payload.planId}`);
  }

  let url = APIs.FETCH_PLAN + `?${params.join("&")}`;

  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_meal);
  }

  return axiosInstance.get(url);
};

export const getPlanDetails = (payload) => {
  let params = [];
  if (payload.startDate) {
    params.push(`startDate=${payload.startDate}`);
  }
  if (payload.endDate) {
    params.push(`endDate=${payload.endDate}`);
  }
  let url = APIs.FETCH_PLAN_DETAILS + payload.plan_id + `?${params.join("&")}`;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_plan_details);
  }
  return axiosInstance.get(url);
};

export const getLoggedMeals = (payload) => {
  let params = [];
  if (payload.startDate) {
    params.push(`startDate=${payload.startDate}`);
  }
  if (payload.endDate) {
    params.push(`endDate=${payload.endDate}`);
  }
  if (payload.planId) {
    params.push(`planId=${payload.planId}`);
  }

  let url = APIs.FETCH_LOGGED_MEALS + `?${params.join("&")}`;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_logged_meals);
  }
  return axiosInstance.get(url);
};

export const getPlanAnalysis = (payload) => {
  let params = [];
  if (payload.startDate) {
    params.push(`startDate=${payload.startDate}`);
  }
  if (payload.endDate) {
    params.push(`endDate=${payload.endDate}`);
  }
  let url =
    APIs.FETCH_ANALYSIS_PLAN_ID + payload.plan_id + `?${params.join("&")}`;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_plan_analysis);
  }
  return axiosInstance.get(url);
};

export const updatePlanStatus = (payload) => {
  let url = APIs.UPDATE_PLAN_STATUS;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.update_plan_status);
  }
  return axiosInstance.put(url, payload);
};

export const getAllPlans = (payload) => {
  let url = APIs.FETCH_ALL_PLANS;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_all_plans);
  }
  return axiosInstance.get(url);
};

export const customisePlan = (payload) => {
  let url = APIs.CUSTOMISE_PLAN;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.customise_plan);
  }
  return axiosInstance.post(url, payload);
};

export const createAsyncPlan = (payload) => {
  let url = APIs.CREATE_ASYNC_PLAN;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.async_plan);
  }
  return axiosInstance.post(url, payload);
};

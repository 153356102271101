import * as APIs from "../constants/BackendConstant";
import { axiosInstance } from "../utilities/configureAxios";

export const initPayment = (productName, provider, meta = {}) => {
  let url = APIs.ORDERS;
  return axiosInstance.post(url, {
    product: productName,
    provider: provider,
    metadata: meta,
  });
};

export const getPaymentDetails = (orderId, productName) => {
  let url = APIs.ORDERS;
  if (orderId) url += `?orderId=${orderId}`;
  else if (productName) url += `?product=${productName}`;
  return axiosInstance.get(url);
};

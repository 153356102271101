import React from "react";
import whiteArrow from "../assets/images/white_right.svg";
import { RedoOutlined } from "@ant-design/icons";

function PrimaryButton({
  key = "",
  text,
  textCustomClass = "",
  paddingY = "py-4",
  customClass = "px-8",
  width = null,
  icon = null,
  onClick,
  showIcon = true,
  color = "bg-primary",
  textColor = "text-white",
  justify = "justify-start",
  textSize = "text-label-large",
  borderRadius = "rounded-xl",
  loading = false,
  marginTop = "mt-4",
  disabled = false,
  id = null,
  iconClass = "w-6 h-6",
}) {
  return (
    <div
      {...(id && { id })}
      key={key}
      onClick={loading || disabled ? () => {} : onClick}
      className={`${width ?? "w-full"} ${customClass} ${marginTop} ${paddingY} ${disabled ? "bg-gray-300" : color} ${borderRadius} justify-center items-center gap-2.5 flex cursor-pointer`}
    >
      <div className={`${justify} items-center gap-4 flex relative`}>
        {loading && (
          <div className="w-full h-full flex justify-center items-center absolute top-0 left-0">
            <RedoOutlined className={`text-white ${textSize}`} spin />
          </div>
        )}
        <>
          <div
            className={`${textCustomClass} ${textColor} ${textSize} ${
              loading ? "opacity-0" : "opacity-100"
            }`}
          >
            {text}
          </div>
          {showIcon && <img src={icon ?? whiteArrow} className={iconClass} />}
        </>
      </div>
    </div>
  );
}

export default PrimaryButton;

import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../contexts/GlobalContext";

export const withStore = (WrappedComponent) => {
  return function WithStoreWrapper(props) {
    const store = useContext(GlobalContext);
    const [storeData, setStoreData] = useState(store.data);

    useEffect(() => {
      setStoreData(store.data);
    }, [store.data]);

    return (
      <WrappedComponent store={{ ...store, data: storeData }} {...props} />
    );
  };
};

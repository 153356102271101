import { CloseCircleFilled } from "@ant-design/icons";
import { Drawer, Rate } from "antd";
import { useEffect, useState } from "react";
import PrimaryButton from "../subComponents/PrimaryButton";
import { triggerStoreReview } from "../utilities/helper";
import { createRating, updateRating, getRatings } from "../dataManager/Rating";
import { useTranslation } from "react-i18next";
import { analyticsAppTrack } from "../utilities/helper";

const RatingsModule = () => {
  const localStorageRatings = localStorage.getItem("ratings");
  const [stars, setStars] = useState(
    localStorageRatings ? parseInt(localStorageRatings) : 0
  );
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(
    localStorageRatings ? false : true
  );
  const [ratingId, setRatingId] = useState(null);

  const { t } = useTranslation();

  const drawerStyles = {
    content: {
      overflow: "visible",
    },
  };

  const updateLocalStorage = (stars) => {
    localStorage.setItem("ratings", stars);
  };

  const getRatingsFromAPI = async () => {
    try {
      const response = await getRatings();
      if (response.data.length > 0) {
        const rating = response.data[0].rating;
        const id = response.data[0].id;
        setStars(rating);
        setRatingId(id);
        updateLocalStorage(rating);
        setIsVisible(false);
        setDrawerOpen(false);
      }
    } catch (error) {
      console.error("Error fetching ratings:", error);
    }
  };

  useEffect(() => {
    getRatingsFromAPI();
  }, []);

  useEffect(() => {
    if (drawerOpen) {
      analyticsAppTrack("rating_module_initiate", {
        stars: stars,
      });
    }
  }, [drawerOpen]);

  useEffect(() => {
    const sendRating = async () => {
      if (ratingId) {
        await updateRating(ratingId, stars);
        updateLocalStorage(stars);
      } else {
        const response = await createRating(stars);
        setRatingId(response.data.id);
        updateLocalStorage(stars);
      }
    };

    if (stars > 0 && drawerOpen) {
      sendRating();
    }
  }, [drawerOpen, stars]);

  const triggerStoreReviewAndClose = () => {
    analyticsAppTrack("rating_module_store_trigger");
    triggerStoreReview();
    setDrawerOpen(false);
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  // if (!isInternalUser()) {
  //   return null;
  // }

  return (
    <div className="p-2 w-full">
      <div className="relative bg-white rounded-xl w-full flex flex-col gap-4 p-2 justify-center items-center border-2 border-primaryLight shadow-lg">
        <CloseCircleFilled
          onClick={() => {
            setIsVisible(false);
            setDrawerOpen(false);
          }}
          className="text-xl cursor-pointer absolute -top-2 -right-2"
        />
        <p className="text-title-medium">{t("rate_your_experience")}</p>
        <Rate
          value={stars}
          onChange={(value) => {
            setStars(value);
            setDrawerOpen(true);
          }}
          style={{
            fontSize: "40px",
          }}
        />
        <Drawer
          closable={false}
          placement="bottom"
          open={drawerOpen}
          onClose={() => {
            analyticsAppTrack("rating_module_closed");
            setDrawerOpen(false);
          }}
          className="relative rounded-t-[32px]"
          styles={drawerStyles}
          height={"fit-content"}
          style={{
            height: "fit-content",
            display: "inline-block",
            minHeight: "fit-content",
          }}
        >
          <div className="rounded-full absolute left-1/2 -translate-x-1/2 -top-16 flex justify-center items-center">
            <CloseCircleFilled
              onClick={() => {
                analyticsAppTrack("rating_module_closed");
                setDrawerOpen(false);
              }}
              className="text-4xl cursor-pointer text-white"
            />
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4 justify-center items-center p-4 bg-white rounded-xl shadow-xl">
              <p className="text-title-medium">{t("rate_your_experience")}</p>
              <Rate
                allowHalf
                value={stars}
                onChange={(value) => {
                  setStars(value);
                }}
                style={{
                  fontSize: "40px",
                }}
              />
              {stars > 0 && (
                <p className="text-body-medium">{t("thank_you_for_ratings")}</p>
              )}
            </div>
            {stars > 3 && (
              <div className="flex flex-col gap-4 justify-center items-center mt-6">
                <p className="text-body-medium">
                  {t("please_rate_us_on_store")}
                </p>
                <div className="flex flex-row gap-4 w-full">
                  <PrimaryButton
                    onClick={() => triggerStoreReviewAndClose()}
                    text={t("continue")}
                    showIcon={false}
                  />
                </div>
              </div>
            )}
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default RatingsModule;

import * as services from "../services/Payments";

export const initPayment = (productName, provider, meta = {}) => {
  return services
    .initPayment(productName, provider, meta)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPaymentDetails = (orderId, productName) => {
  return services
    .getPaymentDetails(orderId, productName)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

import * as APIs from "../constants/BackendConstant";
import { axiosInstance } from "../utilities/configureAxios";
import * as ApiResponse from "../constants/ApiResponse";
import { getMockValue } from "../utilities/helper";
import axios from "axios";

export const getPrompt = (agent) => {
  let url = APIs.GET_PROMPT.replace(":agent", agent);
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_prompt);
  }
  return axiosInstance.get(url);
};

export const deletePrompt = (agent, prompt_id) => {
  let url = APIs.DELETE_PROMPT.replace(":agent", agent).replace(
    ":prompt_id",
    prompt_id
  );
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.delete_prompt);
  }
  return axiosInstance.delete(url);
};

export const updatePrompt = (agent, data) => {
  let url = APIs.UPDATE_PROMPT;
  const payload = {
    agent: agent,
    prompt: data.prompt,
  };
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.update_prompt);
  }
  return axiosInstance.post(url, payload);
};

export const profileConfig = () => {
  let url = APIs.PROFILE_CONFIG;

  if (getMockValue()) {
    return Promise.resolve(ApiResponse.profile_config);
  }
  return axiosInstance.get(url);
};

export const getLanguages = () => {
  let url = APIs.GET_LANGUAGE;

  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_languages);
  }
  return axiosInstance.get(url);
};

export const getDoctorConfig = (type) => {
  let url = APIs.DOCTOR_CONFIG.replace(":identifier_type", type);

  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_doctor_config);
  }
  return axiosInstance.get(url);
};

export const getReportFaqs = (type) => {
  let url = APIs.REPORT_FAQS.replace(":report_identifier", type);

  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_report_faqs);
  }
  return axiosInstance.get(url);
};

export const getGenericContent = (payload) => {
  let url = APIs.GENERIC_CONTENT;

  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_generic_content);
  }
  return axiosInstance.post(url, payload);
};

export const getConfig = (type) => {
  let url = APIs.GET_CONFIG.replace(":identifier", type);

  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_config);
  }
  return axiosInstance.get(url);
};

export const getProducts = (data) => {
  let url = APIs.GET_PRODUCTS;
  let query = [];
  if (data.status) {
    query.push(`status=${data.status}`);
  }
  if (query.length > 0) {
    url += `?${query.join("&")}`;
  }
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_products);
  }
  return axiosInstance.get(url);
};

export const updateOrderDetails = (payload) => {
  let url = APIs.UPDATE_ORDER_DETAILS;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.update_order_details);
  }
  return axiosInstance.put(url, payload);
};

export const getLocation = (userId, phoneNumberCountryCode, ip) => {
  let url = APIs.GET_LOCATION;
  let payload = {
    user_id: userId,
    additional_params: {
      phone_number_country_code: phoneNumberCountryCode,
      ip_address: ip,
    },
  };
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_location);
  }
  return axiosInstance.post(url, payload);
};

export const getIpAddress = () => {
  return axios.get("https://api.ipify.org/?format=json");
};

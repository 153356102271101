import { FEEDBACK } from "../constants/BackendConstant";
import { axiosInstance } from "../utilities/configureAxios";

export const createRating = async (rating) => {
  try {
    const response = await axiosInstance.post(FEEDBACK, {
      rating: parseInt(rating),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRatings = async () => {
  try {
    const response = await axiosInstance.get(FEEDBACK);
    return response.data;
  } catch (error) {
    console.error("Error fetching ratings:", error);
    throw error;
  }
};

export const updateRating = async (id, rating) => {
  try {
    const response = await axiosInstance.put(FEEDBACK, {
      feedbackId: id,
      feedbackRequest: {
        rating: parseInt(rating),
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating rating:", error);
    throw error;
  }
};
